<template>
    <div>
        <div style="margin-top: 20px;display: flex;align-items: center;">
            <div style="font-size: 14px;margin-right: 20px;flex-shrink:0;width: 60px;">查询省份</div>

            <el-select v-model="fromProvince" clearable placeholder="请选择省级" @clear="clearProvince"
                style="margin-right: 10px;" @change="selectProvince" >
                <el-option v-for="(item,index) in creditProvince" :key="index" :label="item.name" :value="item.name">
                </el-option>
            </el-select>

            <el-select v-if="fromProvince==='广西壮族自治区'" @clear="clearCity" @change="selectCity" clearable
                v-model="fromCity" placeholder="请选择市级">
                <el-option v-for="item in cityList" :key="item.value" :label="item.symbol" :value="item.symbol">
                </el-option>
            </el-select>

        </div>


        <div style="margin-top: 20px;display: flex;align-items: center;">
            <div style="font-size: 14px;margin-right: 20px;flex-shrink:0;width: 60px;">评分单位类型</div>

            <el-select v-model="categoryName1" @change="slectCate1" clearable placeholder="请选评分单位类型"
                style="margin-right: 10px;">
                <el-option v-for="(item,index) in categoryList1" :key="index" :label="item.name" :value="item.name">
                </el-option>
            </el-select>

            <el-select v-if="categoryList2.length" @change="slectCate2" v-model="categoryName2"
                style="margin-right: 10px;" placeholder="请选择">
                <el-option v-for="item in categoryList2" :key="item.value" :label="item.name" :value="item.name">
                </el-option>
            </el-select>

            <el-select v-if="categoryList3.length" v-model="categoryName3" placeholder="请选择">
                <el-option v-for="item in categoryList3" :key="item.value" :label="item.name" :value="item.name">
                </el-option>
            </el-select>
        </div>

        <div style="margin-top: 20px;display: flex;align-items: center;" v-if="!fromCity">
            <div style="font-size: 14px;margin-right: 20px;flex-shrink:0;width: 60px;">评分期数</div>

            <el-select v-model="year" @change="slectQuater" placeholder="请选择年度" style="margin-right: 10px;">
                <el-option v-for="(item,index) in yearList" :key="index" :label="item.key" :value="item.key">
                </el-option>
            </el-select>

            <el-select v-model="quarter" style="margin-right: 10px;" placeholder="请选择期数">
                <el-option v-for="item in quaterlist" :key="index" :label="item.key" :value="item.key">
                </el-option>
            </el-select>

        </div>

        <div style="margin-top: 20px;display: flex;align-items: center;">
            <div style="font-size: 14px;margin-right: 20px;flex-shrink:0;width: 60px;">信用分数</div>

            <el-select v-model="evaluationType" @change="slectEvalua" placeholder="请选择信用分数" style="margin-right: 10px;">
                <el-option v-for="(item,index) in evaluationList" :key="index" :label="item.name" :value="item.value">
                </el-option>
            </el-select>
        </div>

        <div style="margin-left: 80px;margin-top: 20px;">
            <div v-if="evaluationType==='score'">
                <div style="display: flex;">
                    <el-input  oninput="value=value.replace(/^\.+|[^\d.]/g,'')" style="margin-right: 20px;" placeholder="（信用分）输入最低分" v-model="startScore" clearable>
                    </el-input>

                    <el-input  oninput="value=value.replace(/^\.+|[^\d.]/g,'')" placeholder="（信用分）输入最高分" v-model="endScore" clearable>
                    </el-input>
                </div>

                <div style="display: flex;">
                    <div  :class="scoreIndex===index&&'sure'" @click="selectScore({item,index})"  style="padding: 10px 8px; border: solid 1px #1890FF;margin-right: 10px;color: #1890FF;margin-top: 20px;"  v-for="(item,index) in scoreList" :key="index">
                       <div v-if="item.startScore"> {{item.startScore}}分-{{item.endScore}}分</div>
                       <div v-else> {{item.endScore}}分以下</div>
                    </div>
                </div>
            </div>

            <div v-if="evaluationType==='good'" style="display: flex;">
                <div class="reset" :class="creditGrade===item&&'sure'" v-for="(item) in goodList"
                    style="margin-right: 10px;" @click="creditGrade=item">{{item}}</div>
            </div>


            <div v-if="evaluationType==='grade'" style="display: flex;">
                <div class="reset" :class="creditGrade===item&&'sure'" v-for="(item) in gradeList"
                    style="margin-right: 10px;" @click="creditGrade=item">{{item}}</div>
            </div>

            <div style="display: flex;align-items: center;margin-top: 20px;">

                <div class="sure" @click="confirm">加入筛选</div>
            </div>
        </div>


    </div>
</template>

<script>
    import {
        mapState,
        mapActions
    } from "vuex"
    import { getCommonColumnList, getCommonColumnChild, getQuater } from "@/api/bid.js"
    export default {
        data() {
            return {
                columnList: '',
                creditProvince: [],
                fromProvince: '',
                fromCity: '',
                fromProvinceAdCode: '',
                cityPid: '',

                categoryList1: [],
                categoryName1: '',
                categoryList2: [],
                categoryName2: '',
                categoryList3: [],
                categoryName3: '',

                quaterlist: [],
                yearList: [],
                year: '',
                quarter: '',
                evaluationList: [{
                    name: '得分',
                    value: 'score',
                }, {
                    name: '优良',
                    value: 'good',
                }, {
                    name: '等级',
                    value: 'grade',
                }],
                evaluationType: 'score',

                startScore: '',

                endScore: '',
                goodList: ['优', '良', '差'],
                creditGrade: '',
                gradeList: ['AAA','AA','A', "B", 'C', ],

                scoreList: [
                { startScore: 90, endScore: 100 },
                { startScore: 80, endScore: 90 },
                { startScore: 60, endScore: 80 },
                { startScore: 0, endScore: 60 },
                ],
                scoreIndex:''

            }
        },
        computed: {
            ...mapState("account", ["regionList"]),
            cityList() {
                //如果是广西 又分几个市 且每个市 专业可能不一样
                if (this.fromProvince.includes('广西')) {
                    console.log('=====市区', this.columnList.filter(item => item.type == '广西信用分类'))
                    return this.columnList.filter(item => item.type == '广西信用分类')
                } else {
                    return []
                }
            },
        },
        watch:{
            categoryName1:function(newVal,oldVal){
                if(newVal){
                    if(newVal.includes('分')){
                        this.evaluationType='score'
                    }
                    if(newVal.includes('良')){
                        this.evaluationType='good'
                    }
                    if(newVal.includes('等级')){
                        this.evaluationType='grade'
                    }
                }
            }
        },
        created() {
            if (!this.regionList.length) {
                this.$store.dispatch("account/getRegion").then(res => {
                    this.getMenu()
                    console.log('======regionList', this.regionList)
                })
            } else {
                this.getMenu()
            }
        },
        mounted() {

        },

        methods: {
            selectScore({item,index}){
                this.scoreIndex=index
                this.startScore=item.startScore
                this.endScore=item.endScore
            },
            //
            confirm() {

                let fomrData = {
                    creditGrade: this.creditGrade,
                    startScore: this.startScore,
                    endScore: this.endScore,
                    fromCity: this.fromCity,
                    fromProvince: this.fromProvince,
                    publicityType:this.categoryName1,
                    subjectSpeciality:this.categoryName2,
                    subjectSpeciality2: this.categoryName3,
                    quarter: this.quarter,
                    year: this.year

                }
                let value=Object.values(fomrData)
                let valueLength=value.filter(item=>item!='')
                if(!valueLength.length){
                    return this.$message({
                        message: '请添加查询条件!',
                        type:'warning',
                        center: true
                    });
                }
                if(!this.fromProvince){
                    return this.$message({
                        message: '请选择查询省份!',
                        type:'warning',
                        center: true
                    });
                }
                
                if(this.startScore&&(!this.endScore||this.endScore==0)){
                    let message=!this.endScore?'请完善分值!':'请输入正确的分值!'
                    return this.$message({
                        message: message,
                        type:'warning',
                        center: true
                    });
                }
                if(this.endScore&&(!this.startScore&&this.startScore!==0)){
                  
                    return this.$message({
                        message: '请完善分值!',
                        type:'warning',
                        center: true
                    });
                }

                if (this.endScore&&this.endScore <= this.startScore) {
                            return this.$message({
                                message: '最小分值不能大于最大分值!',
                                type: 'warning',
                                center: true
                            });
                        }


                // if(this.categoryList2.length){
                //     if(!this.categoryName2){
                //         return this.$message({
                //         message: '请完善评分类型!',
                //         type:'warning',
                //         center: true
                //     });
                //     }
                // }

                // if(this.categoryList3.length){
                //     if(!this.categoryName3){
                //         return this.$message({
                //         message: '请完善评分类型!',
                //         type:'warning',
                //         center: true
                //     });
                //     }
                // }

                // if(this.endScore<=this.startScore){
                //     return this.$message({
                //         message: '请输入正确的分值!',
                //         type:'warning',
                //         center: true
                //     });
                // }
               
                
                this.$emit('creditBack', fomrData)
            },
            clearProvince() { 
                this.categoryList1=[]
            },
            clearCity() {
                this.cityPid = ''
                this.getProvinceColumn()
            },
            backAdcode(val) {
                let adcode = this.creditProvince.filter(item => item.name === val)[0].adCode || ''
                return Number(adcode)
            },
            //选择省份
            selectProvince(val) {
                this.categoryName1 = ''
                this.fromProvince=val
                console.log(' this.fromProvince', this.fromProvince)
                this.fromProvinceAdCode = this.backAdcode(val)
                console.log('= this.fromProvinceAdCode', this.fromProvinceAdCode)
                if (this.fromProvinceAdCode === 350000 || this.fromProvinceAdCode === 450000) {
                    this.retSetData()
                    this.getProvinceColumn()
                } else {
                    this.retSetData()
                    this.categoryList()
                }
            },
            retSetData() {
                this.cityPid = ''
                this.categoryList1 = []
                this.categoryName1 = ''
                this.categoryList2 = []
                this.categoryName2 = ''
                this.categoryList3 = []
                this.categoryName3 = ''
                this.quaterlist = []
                this.yearList = []
                this.year = ''
                this.quarter = ''
                this.startScore = ''
                this.endScore = ''
                this.creditGrade = ''
                this.fromCity = ''
                this.scoreIndex=''
            },
            retSetAllData(){
                this.fromProvince = ''
                this.categoryList1 = []
                this.categoryName1 = ''
                this.categoryList2 = []
                this.categoryName2 = ''
                this.categoryList3 = []
                this.categoryName3 = ''
                this.quaterlist = []
                this.yearList = []
                this.year = ''
                this.quarter = ''
                this.startScore = ''
                this.endScore = ''
                this.creditGrade = ''
                this.fromCity = ''
                this.scoreIndex=''
            },
            categoryList() {
                console.log('=====fromProvinceAdCode', this.fromProvinceAdCode)
                let id = this.columnList.filter(item => item.type == this.fromProvinceAdCode)[0]?.id
                let list = this.columnList.filter(item => item.pid == id)
                let V0list = list.filter(item => item.name === '企业')
                this.categoryV1List(V0list)
            },
            //公式类型 企业 人员
            categoryV1List(V0list) {
                let pid = V0list.filter(item => item.name == '企业')[0]?.id || ''
                let categoryList1 = this.columnList.filter(item => item.pid == pid && item.name != '期数' && item.name != '查询排序')
                this.categoryList1 = categoryList1
                
            },
            slectCate1(name) {
                this.categoryName2 = ''
                this.categoryList2 = []
                this.categoryName3 = ''
                this.categoryList3 = []
                this.categoryV2List()
                this.getQuater()
            },

            //资质名称
            categoryV2List() {
                if (!this.categoryName1) return
                let pid = this.categoryList1.filter(item => item.name == this.categoryName1)[0]?.id || ''
                this.categoryList2 = this.columnList.filter(item => item.pid == pid && item.name != '期数' && item.name != '查询排序')
                console.log('===this.categoryList2',this.categoryList2)
                this.categoryV3List()

            },
            slectCate2() {
                this.categoryName3 = ''
                this.categoryList3 = []
                this.categoryV3List()

            },
            //选择季度
            slectQuater(key) {
                this.quarter = ''
                let index = this.yearList.findIndex(item => item.key === key)
               
                this.quaterlist = this.yearList[index].subResultList
            },
            async getQuater() {
                try {
                    let params = {
                        fromProvince: this.fromProvince,
                    }

                    if (this.fromProvince === '广西壮族自治区' || this.fromProvince === '福建省') {
                        params.scoreName = this.categoryName1
                    } else {
                        params.publicityType = this.categoryName1
                        params.subjectType = "企业"
                    }

                    let { data } = await getQuater(params)
                    this.yearList = data
                   
                } catch (error) {

                }
            },
            //选择评分类型
            slectEvalua(val) {
                this.evaluationType = val
                this.creditGrade = ''
                this.startScore = ''
                this.endScore = ''
                this.scoreIndex=''
            },
            //资质等级
            categoryV3List() {
                if (!this.categoryName2) return
                let pid = this.categoryList2.filter(item => item.name == this.categoryName2)[0]?.id || ''
                this.categoryList3 = this.columnList.filter(item => item.pid == pid && item.name != '期数' && item.name != '查询排序')
            },
            //选择市
            selectCity(val) {
                this.categoryName1 = ''
                if (!val) return
                let pid = this.cityList.filter(item => item.symbol === val)[0].id
                this.cityPid = pid
                this.getProvinceColumn()
             
            },
            //获取广西福建的专业
            async getProvinceColumn() {
                try {
                    let data = ''
                    //广西下的市的专业
                    if (this.cityPid) {
                        data = await getCommonColumnChild({
                            appid: 'wxbd94b37dd45c1f91',
                            pid: this.cityPid
                        })
                        this.categoryList1 = data.data
                    } else {
                        data = await getCommonColumnChild({
                            type: this.fromProvinceAdCode,
                            symbol: '广西福建信用分',
                            appid: 'wxbd94b37dd45c1f91'
                        })
                        this.categoryList1 = data.data[0]?.child

                       

                    }
                } catch (e) {
                    console.log('广西福建异常', data)
                    //TODO handle the exception
                }
            },

            async getMenu() {
             
                try {
                    let { data } = await getCommonColumnList({ appid: 'wxbd94b37dd45c1f91' })
                    this.columnList = data
                    let columnList = data
                    let adcode = columnList.map(item => {
                        if (item.type) {
                            return item.type
                        }
                    }).filter(item => item && item != undefined && item.includes('000') && !item.includes('备案'))

                    //先去重 后筛选
                    let checkSame = Array.from(new Set([...adcode]))

                    let provinces = []
                    checkSame.forEach(child => {
                        this.regionList.forEach(item => {
                            if (item.adCode == child) {
                                provinces.push({
                                    name: item.name,
                                    adCode: item.adCode
                                })
                            }
                        })
                    })

                    //按照adcode排序
                    provinces.sort(function (a, b) {
                        return a.adCode - b.adCode
                    })

                    this.creditProvince = provinces
                  
                } catch (error) {
                    console.log('----栏目', error)
                }
            },
        }
    }
</script>

<style scoped>
    .reset {
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #1890FF;
        color: #1890FF;
        padding: 8px 26px;
        font-size: 14px;
        cursor: pointer;
    }

    .sure {
        color: #FFFFFF!important;
        background: #1890FF;
        border-radius: 2px 2px 2px 2px;
        padding: 8px 26px;
        font-size: 14px;
        cursor: pointer;
    }
</style>